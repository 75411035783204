import React from "react"
import trustImage from "../../images/trust-img.png"

export default function trustCompany() {
  return (
    <div className="trusts">
      <div className="container">
        <div className="header">
          Partners that have seen results and trust LUCA Plus
        </div>
        <div className="main-img sm:block hidden">
          <img src={trustImage} />
        </div>
        <div className="main-img-mob sm:hidden">
          <div className="mover-1"/>
          <div className="mover-2"/>
        </div>
      </div>
    </div>
)
}
