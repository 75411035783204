import React, {useState} from "react"
import arrowImg from "../../images/partner/arrow1.svg"
import {Link, navigate} from "gatsby"
import Modali, {useModali} from "../modal";
import ReactGA from "react-ga";
import arrow1Icom from "../../images/partner/arrow1.svg"

export default function Hero({page, toggleExistingOrgModal}) {
  const [confirmationModal, toggleConfirmationModal] = useModali()
  const [selectedOption, handleSelectedOption] = useState("");

  const goSignup = e => {
    ReactGA.event({
      category: 'Partner Page_Find out more_Button',
      action: 'Partner Page_Find out more_Button clear'
    });
    navigate("/signup/")
  }

  const onSignUpClick = () => {
    ReactGA.event({
      category: 'Partner Page_Sign up_Green button',
      action: 'Partner Page_Sign up_Green button clicked'
    });
    toggleConfirmationModal();
  }
  return (
    <div className="hero banner text-white text-center sm:text-left">
      <div className="container sm:px-10">
        <div className="title text-2xl sm:text-40 font-bold sm:leading-47">
          Partner Success Program for Finance Brokers
        </div>
        <div className="desc max-w-lg text-17 leading-5 sm:leading-6 mt-3 ">
          Join the LUCA Plus Partner Success Program to foster business growth for you and your clients
        </div>

        <div className="flex btn-wrap">
          <Link
              to={`/signup/`}
              state={{ isFindOut: true, blbpartner: true }}
          >
            <button
                onClick={goSignup}
                className="bg-mind rounded partner-but sm:pr-12 focus:outline-none mt-8 leading-5 relative flex items-center sm:ml-0"
                // className="text-white rounded find-out-but sm:pr-12 focus:outline-none mt-8 leading-5 relative
                // flex items-center sm:ml-0"
            >
              Find Out More
              <img src={arrow1Icom} className="ml-2" />
            </button>
          </Link>
          <button
            onClick={toggleExistingOrgModal}
            className="bg-mind rounded partner-but
           sm:pr-12 focus:outline-none mt-8 leading-5 relative flex items-center ml-5"
          >
            Partner Signup
            <img
              src={arrowImg}
              className="hidden sm:block ml-3"
            />
          </button>
        </div>
      </div>
      <Modali.Modal {...confirmationModal} className=" error-modal">
          <div className="pt-12 pop-up">
            <div className="pop-up-inner mx-auto">
              <div className=" text-neutral-3 mt-4">
                Are you an existing XERO, MYOB or QuickBooks user?
              </div>
              <div className="mt-8 mb-20">
                <Link
                    to={`/sign-up/`}
                    state={{ isTrial: true, plan: "blb-partner", price: 0, page: page }}
                >
                  <input
                      className="option cursor-pointer"
                      type="radio"
                      value="yes"
                      name="option"
                      checked={selectedOption === "yes"}
                      onClick={() => handleSelectedOption("yes")}
                  />
                  <label className="ml-2" htmlFor="option-1">
                    Yes
                  </label>
                </Link>
                <Link
                    to={`/sign-up/`}
                    state={{ isTrial: true, partner: true,  page: page, plan: "blb-partner" }}
                    className="ml-12"
                >
                  <input
                      className="cursor-pointer"
                      type="radio"
                      value="no"
                      name="option"
                      checked={selectedOption === "no"}
                      onClick={() => handleSelectedOption("no")}
                  />
                  <label className="ml-2" htmlFor="option-1">
                    No
                  </label>
                </Link>
              </div>
            </div>
          </div>
      </Modali.Modal>
    </div>
  )
}
