import React from "react"
import icon3 from "../../images/partner/icon3.svg"
import icon7 from "../../images/partner/icon7.png"
import icon8 from "../../images/partner/icon8.svg"

export default function Team() {
  return (
    <div className="team">
      <div className="inner mx-auto">
        <div className="title text-22 sm:text-28 sm:leading-10 font-bold">
          Australian owned and operated
        </div>
        <div className="desc mt-4 leading-22 text-neutral-3 sm:mx-0 mx-10">
          LUCA Plus is an Australian fintech business, with all development and
          support functions based in Australia.
        </div>

        <div className="blocks text-center mt-10 flex flex-col sm:flex-row sm:flex-wrap justify-between items-center">
          <div className="block1 flex  justify-between items-center  flex-col   ">
            <img src={icon3} />
            <div className="title mt-13 font-medium leading-6 text-secondary">
              Local Support
            </div>
            <div className="desc2 text-15 my-13 leading-23  text-neutral-3  font-light">
              Our team is here to provide you with support via our live chat or
              via our dedicated phone number <br /> +61 03 9005 5308
            </div>
          </div>
          <div className="block2 flex justify-between items-center  flex-col    mt-3 sm:mt-0">
            <img src={icon7} className="w-10 h-10" />
            <div className="title mt-13 font-medium leading-6 text-secondary">
              Australian Team
            </div>
            <div className="desc2  text-15 my-13 leading-23  text-neutral-3 font-light">
              To guarantee high quality throughout our offering, all development
              is carried out in Australia
            </div>
          </div>
          <div className="block3 flex  justify-between items-center flex-col    mt-3 sm:mt-0">
            <img src={icon8} />
            <div className="title mt-13 font-medium leading-6 text-secondary">
              Secure Environment
            </div>
            <div className="desc2  text-15 my-13 leading-23  text-neutral-3 font-light">
              Our physical infrastructure is hosted and managed in a ISO
              27001, SOC 1 & SOC 2, PCI Level 1,
              FISMA Moderate and SOX certified data centre
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
