import React from "react"
import arrowImg from "../../images/partner/g-arrow.svg"
import block_data from "./blockData"
import ReactGA from "react-ga";

export default function Value() {
  const btnClickHandler = (btn, i) => {
    if(btn === "Learn More"){
      ReactGA.event({
        category: `Partner Page_Learn more_Arrow`,
        action: `Partner Page_Learn more_Arrow clicked`
      });
    } else if(btn === "Find Out") {
      ReactGA.event({
        category: `Partner Page_Find out_Arrow`,
        action: `Partner Page_Find out_Arrow clicked`
      });
    } else if(btn === "Download Now") {
      ReactGA.event({
        category: `Partner Page_Download now_Arrow`,
        action: `Partner Page_Download now_Arrow clicked`
      });
    } else {
      ReactGA.event({
        category: `Partner Page_Get started_Arrow ${i}`,
        action: `Partner Page_Get started_Arrow ${i} clicked`
      });
    }
  }

  return (
    <div className="value">
      <div className="container">
        <div
          className="title text-center
         text-xl sm:text-24 font-bold leading-30 sm:leading-38"
        >
          Join the <span className="text-mind">30+</span> business loan and asset finance business partners that have already signed up for our program - don’t be left out!
        </div>

        <div className="blocks flex flex-col sm:flex-row sm:flex-wrap justify-between items-start">
          {block_data.map((item, index) => (
            <div className="block text-left" key={`block-x-${index}`}>
              <div style={{ width: 35, height: 50 }}>
                <img src={item.icon} className="mb-6" />
              </div>
              <div className="title2 text-brand mb-2 font-medium text-17 leading-6">
                {item.title}
              </div>
              <div
                className="desc2 text-neutral-3 text-sm
               mb-6 leading-6"
              >
                {item.desc}
              </div>

              <a
                onClick={() => btnClickHandler(item.but, index + 1)}
                href={item.link}
                className={`link font-medium text-secondary flex items-center leading-18`}
              >
                {item.but}
                <img src={arrowImg} className="inline-block h-full ml-2" />
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
