import React, {useState} from "react"
import editImg from "../../images/partner/edit.svg"
import bookOpenImg from "../../images/partner/book_open.svg"
import {Link} from "gatsby";
import Modali, {useModali} from "../modal";
import ReactGA from "react-ga";

export default function Join() {
  const [confirmationModal, toggleConfirmationModal] = useModali()
  const [selectedOption, handleSelectedOption] = useState("");
  const findOutMoreClick = () => {
    ReactGA.event({
      category: `Partner Page_Find out more white button`,
      action: `Partner Page_Find out more white button clicked`
    });
  }
  const signupNowClick = () => {
    ReactGA.event({
      category: `Partner Page_Sign up now white button`,
      action: `Partner Page_Sign up now white button clicked`
    });
    toggleConfirmationModal();
  }
  return (
    <div className="join flex bg-brand py-16">
      <div className="inner text-white mx-auto">
        <div className="title text-24 sm:text-30 leading-9 sm:leading-40 font-bold ">
          30+ Business loan and asset finance brokers have already joined our Partner Program{" "}
           - Don’t get left behind!
        </div>

        <div className="flex flex-col sm:flex-row justify-center">
          <Link
              onClick={findOutMoreClick}
              to={`/signup/`}
              state={{ isFindOut: true, partner: true }}
          >
            <div className="card mt-8 mx-auto rounded bg-white flex justify-center  items-center">
              <div className=" flex flex-col justify-center items-center">
                <img src={bookOpenImg} className="" />
                <div className="text-secondary mt-1 uppercase">Find Out More</div>
              </div>
            </div>
          </Link>

          {/*<Link*/}
          {/*  to={`/sign-up/`}*/}
          {/*  state={{ isTrial: true, plan: "solo", price: 0 }}*/}
          {/*>*/}
          <a href="#" className="ml-0 sm:ml-16" onClick={signupNowClick}>
            <div className="card mt-8 mx-auto rounded bg-white flex justify-center  items-center">
              <div className=" flex flex-col justify-center items-center">
                <img src={editImg} className="" />
                <div className="text-secondary mt-1 uppercase">Sign up now</div>
              </div>
            </div>
          </a>
          {/*</Link>*/}
      </div>
      </div>
      <Modali.Modal {...confirmationModal} className=" error-modal exist-org-modal" >
        <div className="pt-4">
          <div className="pop-up-inner mx-auto">
            <div className=" text-neutral-3 mt-4">

            </div>
            <div className="mt-8 mb-12 flex flex-col">
              <Link
                to={`/sign-up?plan=combined`}
                state={{ isTrial: true, plan: "combined", price: 0 }}
                className="popup-box cursor-pointer"
              >
                <input
                  className="option cursor-pointer"
                  type="radio"
                  value="xero"
                  name="option"
                  checked={selectedOption === "xero"}
                  onClick={() => handleSelectedOption("xero")}
                />
                <label className="ml-5 w-10/12" htmlFor="option-1">
                  Signup as partner and Link My XERO MYOB or QBO
                </label>
              </Link>
              <Link
                to={`/sign-up?plan=partner`}
                state={{ isTrial: true, plan: "partner", price: 0}}
                className="popup-box cursor-pointer mt-5"
              >
                <input
                  type="radio"
                  value="lucaplus"
                  name="option"
                  checked={selectedOption === "lucaplus"}
                  onClick={() => handleSelectedOption("lucaplus")}
                />
                <label className="w-10/12 ml-5" htmlFor="option-1">
                  Signup as partner without Linking software
                </label>
              </Link>
              <Link
                to={`/sign-up?plan=partner`}
                state={{ isTrial: true, plan: "partner", price: 0 }}
                className="popup-box cursor-pointer mt-5"
              >
                <input
                  type="radio"
                  value="lucaplus"
                  name="option"
                  checked={selectedOption === "lucaplus"}
                  onClick={() => handleSelectedOption("lucaplus")}
                />
                <label className="w-10/12 ml-5" htmlFor="option-1">
                  Don’t have software. Signup up as partner
                </label>
              </Link>
            </div>
          </div>
        </div>
      </Modali.Modal>
    </div>
  )
}
