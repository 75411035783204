
import icon1 from "../../images/partner/icon1.svg"
import icon2 from "../../images/partner/icon2.png"
import icon3 from "../../images/partner/icon3.svg"
import icon4 from "../../images/partner/icon4.svg"
import icon5 from "../../images/partner/icon5.svg"
import icon6 from "../../images/partner/icon6.svg"


const block_data = [
  {
    icon: icon1,
    title: "Easier Cash Flow Management",
    desc: "Make cash flow management easier for your clients’ businesses",
    link: "/signup/",
    but: "Get Started",
  },
  {
    icon: icon2,
    title: "Earn More Income",
    desc: "Invite your clients to use our products through our tailored designed partner portal to earn more reward income",
    link: "/signup/",
    but: "Get Started",
  },
  {
    icon: icon3,
    title: "Make More Deals Faster",
    desc: "Save your and your clients time with a simpler, faster and fully digitalised application process",
    link: "/signup/",
    but: "Get Started",
    btnClassName: "sm:pt-5 pt-0"
  },
  {
    icon: icon4,
    title: "Grow Your Business",
    desc: "Reach new customers and markets with your success story shared through our go to market channels",
    link: "/signup/",
    but: "Learn More",
    btnClassName: "sm:pt-5 pt-0"
  },
  {
    icon: icon5,
    title: "Be Fully Supported",
    desc: "Empower your team with knowledge and proactive customer support system",
    link: "/signup/",
    but: "Find Out",
    btnClassName: "sm:pt-5 pt-0"
  },
  {
    icon: icon6,
    title: "Flexible and Convenient",
    desc: "Give your client more flexibility to spend on other business growth opportunities",
    link: "/ebook/",
    but: "Download Now",
    btnClassName: "sm:pt-5 pt-0"
  },
]


export default block_data
