import React, { useLayoutEffect, useState } from "react"
import ReactGA from "react-ga";

import SEO from "../components/seo"

import Modali, { useModali } from "../components/modal"
import Top from "../components/signup/top"

import Hero from "../components/blb-partner/hero"
import Why from "../components/blb-partner/why"
import Value from "../components/blb-partner/value"
import TrustCompany from "../components/blb-partner/trustCompany"
import Join from "../components/blb-partner/join"
import Team from "../components/blb-partner/team"

import seoImage from "../images/partner/bg_mobile.png"

import NewFooter from "../components/pricing/footer"

import "../scss/style.scss"
import { Link } from "gatsby"

export default function Partner() {
  const [videoModal, toggleVideoModal] = useModali();
  const [existingOrgModal, toggleExistingOrgModal] = useModali();
  const [selectedOption, handleSelectedOption] = useState("");

  const navItemClick = (navItem) => {
        ReactGA.event({
            category: 'NAV_ITEM',
            action: `Clicked ${navItem} Nav Item of Partner Page`
        });
    }
  return (
    <div className="h-full font-robo partner-landing">
      <SEO title="E-invoicing Benefits: Join the LUCA Plus Partner Program today" einvoice={true} description="Join the 40+ Accountants/Bookkeepers who have already joined the LUCA+ Partner Program. Attract new clients & access a new income stream." image={seoImage}
      keywords="e-invoicing benefits" />
      <Top partner={true} toggleVideoModal={toggleVideoModal} navItemClick={navItemClick}/>
      <Hero page="Partner Page" toggleExistingOrgModal={toggleExistingOrgModal} />
      <Value />
      <Why toggleVideoModal={toggleVideoModal} />
      <TrustCompany />
      <Join />
      <Team />
      <NewFooter page='Partner Page' simple={true}/>
      <Modali.Modal {...videoModal}>
        <div className="hero-video">
          <iframe
            src="https://www.youtube.com/embed/L8Q7ScsjLtQ?autoplay=1&enable_js=1"
            title="Introduction to LUCA+"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            frameBorder="0"
            webkitallowfullscreen="true"
            mozallowfullscreen="true"
            allowFullScreen
            allow="autoplay"
          />
        </div>
      </Modali.Modal>
      <Modali.Modal {...existingOrgModal} className=" error-modal exist-org-modal" >
        <div className="pt-4">
          <div className="pop-up-inner mx-auto">
            <div className=" text-neutral-3 mt-4">

            </div>
            <div className="mt-8 mb-12 flex flex-col">
              <Link
                to={`/sign-up?plan=combined`}
                state={{ isTrial: true, plan: "combined", price: 0 }}
                className="popup-box cursor-pointer"
              >
                <input
                  className="option cursor-pointer"
                  type="radio"
                  value="xero"
                  name="option"
                  checked={selectedOption === "xero"}
                  onClick={() => handleSelectedOption("xero")}
                />
                <label className="ml-5 w-10/12" htmlFor="option-1">
                  Signup as partner and Link My XERO MYOB or QBO
                </label>
              </Link>
              <Link
                to={`/sign-up?plan=partner`}
                state={{ isTrial: true, plan: "partner", price: 0}}
                className="popup-box cursor-pointer mt-5"
              >
                <input
                  type="radio"
                  value="lucaplus"
                  name="option"
                  checked={selectedOption === "lucaplus"}
                  onClick={() => handleSelectedOption("lucaplus")}
                />
                <label className="w-10/12 ml-5" htmlFor="option-1">
                  Signup as partner without Linking software
                </label>
              </Link>
              <Link
                to={`/sign-up?plan=partner`}
                state={{ isTrial: true, plan: "partner", price: 0 }}
                className="popup-box cursor-pointer mt-5"
              >
                <input
                  type="radio"
                  value="lucaplus"
                  name="option"
                  checked={selectedOption === "lucaplus"}
                  onClick={() => handleSelectedOption("lucaplus")}
                />
                <label className="w-10/12 ml-5" htmlFor="option-1">
                  Don’t have software. Signup up as partner
                </label>
              </Link>
            </div>
          </div>
        </div>
      </Modali.Modal>
    </div>
  )
}
